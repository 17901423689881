<template>
  <v-layout column>
    <CoreHeader large img="profile.jpg">
      <v-layout class="mx-5" justify-start align-center fill-height>
        <v-flex>
          <h1 class="display-3">
            <span class="font-weight-light">¯\_(ツ)_/¯ Couldn't find that route. Sorry.</span>
          </h1>
        </v-flex>
      </v-layout>
    </CoreHeader>
  </v-layout>
</template>

<script>
export default {
  name: '404',
  components: {
    CoreHeader: () => import('@/components/core/Header')
  }
}
</script>
